import { report } from 'import-inspector';
import loadable from 'src/helpers/loadableWithFetchData';
import RootPageView from 'src/containers/page/RootPageView';
import PageView from 'src/containers/page/PageView';
import NotFoundView from 'src/containers/NotFoundView';

const routes = [
  {
    path: '/',
    component: loadable(() =>
      report(import(/* webpackChunkName: "home" */ './containers/HomeView'), {
        webpackRequireWeakId: () =>
          require.resolveWeak('./containers/HomeView'),
      })
    ),
    exact: true,
  },
  {
    path: '/create',
    component: loadable(() =>
      report(
        import(/* webpackChunkName: "create" */ './containers/CreateView'),
        {
          webpackRequireWeakId: () =>
            require.resolveWeak('./containers/CreateView'),
        }
      )
    ),
  },
  {
    path: '/documentation/upload',
    component: loadable(() =>
      report(
        import(/* webpackChunkName: "documentsUpload" */ './components/OneTimeDocumentsUpload/OneTimeDocumentsUploadView'),
        {
          webpackRequireWeakId: () =>
            require.resolveWeak(
              './components/OneTimeDocumentsUpload/OneTimeDocumentsUploadView'
            ),
        }
      )
    ),
    exact: true,
  },
  {
    path: '/page/support',
    component: loadable(() =>
      report(
        import(/* webpackChunkName: "support" */ './containers/SupportView'),
        {
          webpackRequireWeakId: () =>
            require.resolveWeak('./containers/SupportView'),
        }
      )
    ),
  },
  {
    path: '/page/unsubscribe/:name',
    component: loadable(() =>
      report(
        import(/* webpackChunkName: "support" */ './containers/UnsubscribeView'),
        {
          webpackRequireWeakId: () =>
            require.resolveWeak('./containers/UnsubscribeView'),
        }
      )
    ),
  },
  {
    path: '/page/create',
    redirectTo: '/create',
  },
  {
    path: '/:name',
    component: RootPageView,
    routes: [
      {
        path: '/:name/payment',
        redirectTo: '/:name/dashboard/payment',
      },
      {
        path: '/:name/identity',
        redirectTo: '/:name',
      },
      {
        path: '/:name/address',
        redirectTo: '/:name',
      },
      {
        path: '/:name/tips/payments',
        redirectTo: '/:name/dashboard/payment',
      },
      {
        path: '/:name/dashboard/payment',
        redirectTo: '/:name/funds/account',
      },
      {
        path: '/:name/tips',
        redirectTo: '/:name/dashboard',
      },
      {
        path: '/:name/dashboard',
        component: loadable(() =>
          report(
            import(/* webpackChunkName: "owner" */ './containers/DashboardView'),
            {
              webpackRequireWeakId: () =>
                require.resolveWeak('./containers/DashboardView'),
            }
          )
        ),
        routes: [
          {
            path: '/:name/dashboard/personalise',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/tips/ProfileView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/tips/ProfileView'),
                }
              )
            ),
          },
          {
            path: '/:name/dashboard/promote',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/tips/PromoteView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/tips/PromoteView'),
                }
              )
            ),
          },
          {
            path: '/:name/dashboard/advertise',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/tips/AdvertiseCauseView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/tips/AdvertiseCauseView'),
                }
              )
            ),
          },
        ],
      },
      {
        path: '/:name/funds',
        component: loadable(() =>
          report(
            import(/* webpackChunkName: "owner" */ './containers/funds/RootFundsView'),
            {
              webpackRequireWeakId: () =>
                require.resolveWeak('./containers/funds/RootFundsView'),
            }
          )
        ),
        routes: [
          {
            path: '/:name/funds/fees',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/funds/FeesView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/funds/FeesView'),
                }
              )
            ),
          },
          {
            path: '/:name/funds/account',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/funds/AccountView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/funds/AccountView'),
                }
              )
            ),
          },
          {
            path: '/:name/funds',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/funds/WithdrawView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/funds/WithdrawView'),
                }
              )
            ),
            routes: [
              {
                path: '/:name/funds/additionaldetails',
                component: loadable(() =>
                  report(
                    import(/* webpackChunkName: "owner" */ './containers/AdditionalDetailsContainer'),
                    {
                      webpackRequireWeakId: () =>
                        require.resolveWeak(
                          './containers/AdditionalDetailsContainer'
                        ),
                    }
                  )
                ),
              },
            ],
          },
        ],
      },
      {
        path: '/:name/edit',
        component: loadable(() =>
          report(
            import(/* webpackChunkName: "owner" */ './containers/EditView'),
            {
              webpackRequireWeakId: () =>
                require.resolveWeak('./containers/EditView'),
            }
          )
        ),
      },
      {
        path: '/:name/settings',
        component: loadable(() =>
          report(
            import(/* webpackChunkName: "owner" */ './containers/settings/SettingsView'),
            {
              webpackRequireWeakId: () =>
                require.resolveWeak('./containers/settings/SettingsView'),
            }
          )
        ),
      },
      {
        path: '/:name/updates/:updateId',
        component: PageView,
      },
      {
        path: '/:name',
        component: PageView,
        routes: [
          {
            path: '/:name/coverphoto',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/CoverPhotoView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/CoverPhotoView'),
                }
              )
            ),
          },
          {
            path: '/:name/story',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/StoryView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/StoryView'),
                }
              )
            ),
          },
          {
            path: '/:name/pagelink',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/PageLinkView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/PageLinkView'),
                }
              )
            ),
          },
          {
            path: '/:name/confirmidentity',
            component: loadable(() =>
              import(/* webpackChunkName: "owner" */ './containers/ConfirmIdentityView')
            ),
          },
          {
            path: '/:name/update',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/updates/UpdateView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/updates/UpdateView'),
                }
              )
            ),
            exact: true,
          },
          {
            path: '/:name/update/thank',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/updates/ThankView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/updates/ThankView'),
                }
              )
            ),
          },
          {
            path: '/:name/update/milestone',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/updates/MilestoneView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/updates/MilestoneView'),
                }
              )
            ),
          },
          {
            path: '/:name/update/share',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/updates/ShareView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/updates/ShareView'),
                }
              )
            ),
          },
          {
            path: '/:name/thankyou/:activityHashId',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/ThankYouView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/ThankYouView'),
                }
              )
            ),
          },
          {
            path: '/:name/qrcode',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/QRView'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak('./containers/QRView'),
                }
              )
            ),
          },
          {
            path: '/:name/contact',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/contactOwner/ContactOwner'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak(
                      './containers/contactOwner/ContactOwner'
                    ),
                }
              )
            ),
          },
          {
            path: '/:name/feedback/contact',
            component: loadable(() =>
              report(
                import(/* webpackChunkName: "owner" */ './containers/contactOwner/GiveFeedback'),
                {
                  webpackRequireWeakId: () =>
                    require.resolveWeak(
                      './containers/contactOwner/GiveFeedback'
                    ),
                }
              )
            ),
          },
        ],
      },
    ],
  },
  {
    component: NotFoundView,
  },
];

export default routes;
