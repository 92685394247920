import React from 'react';
import format from 'date-fns/format';
import FacebookShare from '../../FacebookShare/FacebookShare';
import styles from './PageClosedFullyFundedSupporter.scss';
import { PageState } from 'src/redux/modules/page';
import Svg from '@justgiving/svg';
import timer from '@justgiving/icons/lib/timer.svg';
import FormattedCurrency from 'src/components/FormattedCurrency';
import { CROWDFUNDING_BASE_URI } from 'config';
import { FormattedMessage } from 'react-intl';

interface Props {
  page: PageState;
  supporters: { totalCount: number };
}

const GenericSupporter: React.StatelessComponent<Props> = props => {
  const {
    page,
    supporters: { totalCount },
  } = props;

  const { amountRaised, targetCurrency, finishedAt, ownerName } = page;
  const fbButtonLocation = 'page > banner';

  return (
    <div
      id="generic-supporter"
      className={`${
        styles['container-funded']
      } jg-space-pmd jg-bdb jg-bd--solid jg-bd--very-light-grey row jg-text--center`}
    >
      <div className="col-xs-12 col-xl-6 offset-xl-3 jg-text--center">
        <h1 className="jg-h1">
          <FormattedMessage
            id="GenericSupporter.weDidIt"
            defaultMessage="We did it!"
          />
        </h1>
        <p className="jg-space-mbmd">
          <FormattedMessage
            id="GenericSupporter.pageOwnerRaised"
            defaultMessage="{ownerName} raised {amount} from {supporterCount}"
            values={{
              ownerName,
              amount: (
                <b>
                  <FormattedCurrency
                    value={amountRaised}
                    currency={targetCurrency || 'GBP'}
                    minimumFractionDigits={0}
                  />
                </b>
              ),
              supporterCount: (
                <FormattedMessage
                  id="GenericSupporter.pageOwnerRaised.supporters"
                  defaultMessage="{totalCount} {totalCount, plural, one {supporter} other {supporters}}"
                  values={{
                    totalCount,
                  }}
                  tagName="b"
                />
              ),
            }}
          />
        </p>
        <div className="row jg-space-mbmd">
          <div className="col-xs-12 col-md-8 offset-md-2">
            <FacebookShare
              location={fbButtonLocation}
              buttonText="Inspire others with this story"
            />
          </div>
        </div>
        <p className={`jg-text--small ${styles.breakline}`}>
          <FormattedMessage id="GenericSupporter.or" defaultMessage="or" />
        </p>
        <p>
          <a href={`${CROWDFUNDING_BASE_URI}/create`}>
            <FormattedMessage
              id="GenericSupporter.startYourOwnPage"
              defaultMessage="Start your own crowdfunding page"
            />
          </a>
        </p>
        <div className="jg-display-flex jg-flex-aic jg-flex-jcc">
          <Svg
            className={`jg-fill--nobel jg-icon--medium-small jg-space-mbsm jg-space-mrxs ${
              styles.timer
            }`}
            markup={timer}
          />
          <p className="jg-space-mbn jg-text--small">
            <FormattedMessage
              id="GenericSupporter.closedAt"
              defaultMessage="Closed {date}"
              values={{ date: format(finishedAt!, 'DD/MM/YYYY') }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default GenericSupporter;
