import { getConsentStatements } from 'src/helpers/api/preferencesService';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { ConsentStatement } from './consentStatement';
import { USER_CONSENTS } from 'config';

export interface GetConsentStatementsRequestAction {
  type: 'GET_CONSENT_STATEMENTS_REQUEST';
}

export interface GetConsentStatementsSuccessAction {
  type: 'GET_CONSENT_STATEMENTS_SUCCESS';
  payload: ConsentStatement[];
}

export interface GetConsentStatementsFailureAction {
  type: 'GET_CONSENT_STATEMENTS_FAILURE';
  payload: Error;
}

export interface GetUserConsentStatementsRequestAction {
  type: 'GET_USER_CONSENT_STATEMENTS_REQUEST';
}

export interface GetUserConsentStatementsSuccessAction {
  type: 'GET_USER_CONSENT_STATEMENTS_SUCCESS';
  payload: ConsentStatement[];
}

export interface GetUserConsentStatementsFailureAction {
  type: 'GET_USER_CONSENT_STATEMENTS_FAILURE';
  payload: Error;
}

export type GetConsentStatementsActions =
  | GetConsentStatementsRequestAction
  | GetConsentStatementsSuccessAction
  | GetConsentStatementsFailureAction
  | GetUserConsentStatementsRequestAction
  | GetUserConsentStatementsSuccessAction
  | GetUserConsentStatementsFailureAction;

export function getConsents() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch({
      type: 'GET_CONSENT_STATEMENTS_REQUEST',
    });

    try {
      const result = await getConsentStatements(
        USER_CONSENTS.COLLECTION_LOCATION_ID
      );

      if (!result) {
        throw new Error('No result from preferences service request.');
      }
      dispatch({
        type: 'GET_CONSENT_STATEMENTS_SUCCESS',
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: 'GET_CONSENT_STATEMENTS_FAILURE',
        payload: e,
      });
    }
  };
}

export function getUserConsents() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch({
      type: 'GET_USER_CONSENT_STATEMENTS_REQUEST',
    });

    try {
      const result = await getConsentStatements(
        USER_CONSENTS.CROWDFUNDING_PAGE_COLLECTION_LOCATION_ID
      );

      if (!result) {
        throw new Error('No result from preferences service request.');
      }
      dispatch({
        type: 'GET_USER_CONSENT_STATEMENTS_SUCCESS',
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: 'GET_USER_CONSENT_STATEMENTS_FAILURE',
        payload: e,
      });
    }
  };
}

export interface Params {
  checked: boolean;
  index: string;
}

export interface UpdateUserConsent {
  type: 'UPDATE_USER_CONSENT';
  payload: Params;
}

export type UpdateUserConsentActions = UpdateUserConsent;

export function updateUserConsent(data: Params) {
  return {
    type: 'UPDATE_USER_CONSENT',
    payload: data,
  };
}
