/* global ga */

import { GOOGLE_ANALYTICS_KEY } from '../../../config';

if (__CLIENT__) {
  window.addEventListener('CookiebotOnAccept', function(e) {
    if (Cookiebot.consent && Cookiebot.consent.statistics) {
      if (__CLIENT__ && window._kiq) {
        const $script = require('scriptjs');
        $script('//www.google-analytics.com/analytics.js');
        window.ga =
          window.ga ||
          function google(...args) {
            (ga.q = ga.q || []).push(args);
          };
        ga.l = +new Date();
        ga('create', GOOGLE_ANALYTICS_KEY, 'auto');
      }
    }
  });
}

function gaPageView(location) {
  ga('set', 'page', location);
  // Static title is 'Good Enough' for now
  ga('set', 'title', 'Crowdfunding');
  ga('send', 'pageview');
}

const googleAnalytics = ({ getState }) => next => action => {
  const state = getState();
  const pagePath = state.routing.location
    ? state.routing.location.pathname
    : '';
  const pagePathSlice = pagePath.slice(0, pagePath.lastIndexOf('/'));

  switch (action.type) {
    case 'PAGE_SUCCESS':
      gaPageView(pagePath);
      break;
    case 'BANK_SUCCESS':
      if (action.approved === true) {
        gaPageView(`${pagePathSlice}/pagecomplete`);
      } else {
        gaPageView(`${pagePathSlice}/pagepending`);
      }
      break;
    case 'BANK_FAILURE':
      gaPageView(`${pagePathSlice}/bankfailure`);
      break;
    case 'ACTIVATE_SUCCESS':
      gaPageView(`${pagePath}/pageactive`);
      break;
    case 'CREATE_START':
      gaPageView('/create');
      break;
    default:
      break;
  }

  return next(action);
};

export default googleAnalytics;
