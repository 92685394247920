import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';
import renderRoutes from 'src/helpers/renderRoutes';
import { CROWDFUNDING_BASE_URI, FB_APP_ID } from 'config';

import 'src/styles/crowdfunding.less';
import 'src/styles/dna-global/dna-global.css';
import 'src/styles/app.scss';
import routes from 'src/routes';
import AppErrorBoundary from 'src/components/AppErrorBoundary';
import Chrome from '@justgiving/chrome-react';

class App extends Component {
  static propTypes = {
    location: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <AppErrorBoundary>
        <Helmet
          title="Crowdfunding for Good from JustGiving"
          meta={[
            { name: 'title', content: 'Crowdfunding For Good From JustGiving' },
            {
              name: 'description',
              content:
                'With JustGiving Crowdfunding, anyone can raise money to fund their own project. If it benefits a friend in need, or a local or overseas community.',
            },
            {
              name: 'keywords',
              content:
                'donate,charity,fundraise,donation,raise,money,giftaid,justgiving',
            },

            { property: 'fb:app_id', content: FB_APP_ID },

            { property: 'og:title', content: 'JustGiving' },
            { property: 'og:site_name', content: 'JustGiving' },
            {
              property: 'og:description',
              content:
                'Raise money, support your friends, give to a cause. Show you care.',
            },
            { property: 'og:url', content: CROWDFUNDING_BASE_URI },
            {
              property: 'og:image',
              content: 'https://www.justgiving.com/favicon-194x194.png',
            },

            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: 'justgiving' },
            { name: 'twitter:title', content: 'Join the JustGiving community' },
            {
              name: 'twitter:description',
              content:
                'Raise money, support your friends, give to a cause. Show you care.',
            },
            {
              name: 'twitter:image:src',
              content: 'https://www.justgiving.com/favicon-194x194.png',
            },
            { name: 'twitter:domain', content: 'justgiving.com' },
            { name: 'twitter:app:country', content: 'GB' },
            { name: 'twitter:app:name:iphone', content: 'JustGiving' },
            { name: 'twitter:app:id:iphone', content: '365315851' },
            { name: 'twitter:app:name:ipad', content: 'JustGiving' },
            { name: 'twitter:app:id:ipad', content: '365315851' },
            {
              name: 'twitter:app:id:googleplay',
              content: 'com.justgiving.app',
            },

            {
              name: 'apple-mobile-web-app-title',
              content: 'JustGiving Crowdfunding',
            },
            { name: 'application-name', content: 'JustGiving Crowdfunding' },
            { name: 'msapplication-TileColor', content: '#ffffff' },
            { name: 'theme-color', content: '#ffffff' },

            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, user-scalable=no',
            },
          ]}
          link={[
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: '/apple-touch-icon.png',
            },
            {
              rel: 'icon',
              sizes: '32x32',
              type: 'image/png',
              href: 'https://www.jg-cdn.com/favicons/favicon-32x32.png',
            },
            {
              rel: 'icon',
              sizes: '194x194',
              type: 'image/png',
              href: 'https://www.jg-cdn.com/favicons/favicon-194x194.png',
            },
            {
              rel: 'icon',
              sizes: '192x192',
              type: 'image/png',
              href:
                'https://www.jg-cdn.com/favicons/android-chrome-192x192.png',
            },
            {
              rel: 'icon',
              sizes: '16x16',
              type: 'image/png',
              href: 'https://www.jg-cdn.com/favicons/favicon-16x16.png',
            },
            {
              rel: 'manifest',
              href: 'https://www.jg-cdn.com/favicons/manifest.json',
            },
            {
              rel: 'mask-icon',
              href: 'https://www.jg-cdn.com/favicons/safari-pinned-tab.svg',
              color: '#7a04dd',
            },
          ]}
        />
        <Chrome headerVariant="consumer-crowdfunding" footerVariant="full" />
        {renderRoutes(routes)}
      </AppErrorBoundary>
    );
  }
}

export default withRouter(App);
