import PropTypes from 'prop-types';
import React from 'react';
import asyncConnect from 'src/helpers/asyncConnect';
import renderRoutes from 'src/helpers/renderRoutes';
import OwnerPageView from 'src/containers/page/OwnerPageView';
import ErrorPageView from 'src/containers/page/ErrorPageView';
import { loadPage } from 'src/redux/modules/page';
import { loadCategories } from 'src/redux/modules/categories';
import { push } from 'react-router-redux';

const fetchData = async (dispatch, params) => {
  const loadPagePromise = dispatch(loadPage(params.name));
  const loadCategoriesPromise = dispatch(loadCategories());

  await Promise.all([loadPagePromise, loadCategoriesPromise]);
};

const mapStateToProps = ({ page }) => ({
  isOwnerRequested: !!page.isOwnerRequested,
  failType: page.failType,
});

class RootPageView extends React.Component {
  render() {
    const { failType, isOwnerRequested, route, ...rest } = this.props;

    if (failType) {
      return <ErrorPageView {...rest} />;
    }

    if (isOwnerRequested) {
      return <OwnerPageView route={route} {...rest} />;
    }

    return renderRoutes(route.routes);
  }
}

RootPageView.propTypes = {
  isOwnerRequested: PropTypes.bool.isRequired,
  failType: PropTypes.string,
  fetchData: PropTypes.func,
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default asyncConnect(RootPageView, {
  mapStateToProps,
  fetchData,
  id: 'ROOT_PAGE_VIEW',
});
