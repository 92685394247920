import { push } from 'react-router-redux';
import {
  get as getSelf,
  isLoggedIn as isUserLoggedIn,
} from 'src/helpers/api/self';
import { create, CreatePagePayload, PageResource } from 'src/helpers/api/pages';
import { updateUserConsents } from 'src/helpers/api/preferencesService';
import { isLoggedIn } from 'src/helpers/api/iam';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import { AnalyticsMeta } from 'src/redux/modules/analytics';
import { LOGIN_URI_SSO } from 'config';
import { gotoUrl } from 'src/helpers/url';

export const CREATE_START = 'CREATE_START';

interface CreateStartAction {
  type: 'CREATE_START';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function createPageStarted(): CreateStartAction {
  return {
    type: CREATE_START,
    meta: {
      analytics: {
        event: 'page view',
        subtype: 'create',
      },
    },
  };
}

interface CreateRequestAction {
  type: 'CREATE_REQUEST';
  payload: CreatePagePayload;
  meta: {
    analytics: AnalyticsMeta;
  };
}

interface CreateSuccessAction {
  type: 'CREATE_SUCCESS';
  payload: PageResource;
}

interface CreateFailureAction {
  type: 'CREATE_FAILURE';
  error: true;
  payload: Error;
}

interface CreateCheckpointAction {
  type: 'CREATE_CHECKPOINT';
  payload: CreatePagePayload;
}

interface CreateAbortedAction {
  type: 'CREATE_ABORTED';
}

export function createPage(
  page: CreatePagePayload,
  isCharityEndorsed: boolean
) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const analytics = {
      event: 'link click',
      subtype: 'button',
      event_value: 'continue',
      page_section: 'page creation > bottom',
      ...(isCharityEndorsed
        ? {
            actor_sources: 'charity endorsed checkbox',
            actor_id: page.charityId
              ? page.commitmentType
              : 'checkbox not ticked',
          }
        : {}),
    };

    dispatch<CreateRequestAction>({
      type: 'CREATE_REQUEST',
      payload: page,
      meta: {
        analytics,
      },
    });

    dispatch<CreateCheckpointAction>({
      type: 'CREATE_CHECKPOINT',
      payload: page,
    });

    if (__CLIENT__ && !isLoggedIn()) {
      dispatch<CreateAbortedAction>({ type: 'CREATE_ABORTED' });
      gotoUrl(`${LOGIN_URI_SSO}?returnUrl=${encodeURI(window.location.href)}`);

      return;
    }

    try {
      await advance(dispatch, getState);
    } catch (error) {
      dispatch<CreateFailureAction>({
        type: 'CREATE_FAILURE',
        error: true,
        payload: error,
      });
    }
  };
}

async function advance(
  dispatch: Dispatch<AppState>,
  getState: () => AppState,
  beforeRedirect?: () => void
) {
  const user = await getSelf();

  if (!user || !isUserLoggedIn(user)) {
    throw new Error(
      'Expected user to be logged in when advancing page creation.'
    );
  }

  const data = await finishPageCreation(dispatch, getState);

  if (beforeRedirect) {
    beforeRedirect();
  }

  dispatch(push(`/${data.name}/coverphoto`));
}

async function finishPageCreation(
  dispatch: Dispatch<AppState>,
  getState: () => AppState
) {
  const {
    create: { page },
    consents: { userConsentStatements },
  } = getState();

  if (!page) {
    throw new Error("Can't finish page creation, page data not in store");
  }

  const data = await create(page);
  /**
   * updateUserConsents is called in the background
   * no need for error handling and waiting for the response
   */
  updateUserConsents({
    userConsentStatements,
    userGuid: data.ownerUserId,
    pageId: data.id,
  });

  dispatch<CreateSuccessAction>({ type: 'CREATE_SUCCESS', payload: data });

  return data;
}

interface CreateCategoryChanged {
  type: 'CREATE_CATEGORY_CHANGED';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function categoryChanged({
  categoryName,
}: {
  categoryName: string;
}): CreateCategoryChanged {
  return {
    type: 'CREATE_CATEGORY_CHANGED',
    meta: {
      analytics: {
        event: 'click',
        subtype: 'drop down',
        event_value: 'category',
        page_section: 'page creation',
        actor_id: categoryName,
        actor_source: 'drop_down',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

type CreateAction =
  | CreateStartAction
  | CreateAbortedAction
  | CreateCheckpointAction
  | CreateRequestAction
  | CreateSuccessAction
  | CreateFailureAction
  | CreateCategoryChanged;

export interface CreateState {
  userId?: string;
  isStateVerified: boolean;
  emailAddress: string | null;
  errorMessage: string | null;
  isRequesting: boolean;
  isRequestingFacebook: boolean;
  page: CreatePagePayload | null;
  hasError?: boolean;
  firstName?: string;
  lastName?: string;
  profileImage?: string;
}

const initialState: CreateState = {
  isStateVerified: false,
  emailAddress: null,
  errorMessage: null,
  isRequesting: false,
  isRequestingFacebook: false,
  page: null,
};

export default function reducer(
  state: CreateState = initialState,
  action: CreateAction
): CreateState {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return {
        ...state,
        isRequesting: true,
      };

    case 'CREATE_CHECKPOINT':
      return {
        ...state,
        page: action.payload,
      };

    case 'CREATE_ABORTED':
      return {
        ...state,
        isRequesting: false,
      };

    case 'CREATE_SUCCESS':
      return {
        ...state,
        page: null,
        isRequesting: false,
      };

    case 'CREATE_FAILURE':
      return {
        ...state,
        isRequesting: false,
        hasError: true,
        errorMessage: action.payload.message,
      };

    default:
      return state;
  }
}
